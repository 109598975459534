import React, { useState } from 'react';
import { TextField, Checkbox, FormControlLabel, Button, Box, Typography, InputLabel, Select, MenuItem, FormControl } from '@mui/material';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { setProfileData } from "../../profile/profileSlice";
import { toast } from "react-toastify";
import api from "../../api/api";
import { CountriesList } from "../../common/countries-list";
import pl from 'react-phone-number-input/locale/pl.json'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import "./Profile.css";

interface ProfileProps { formTitle?: string }

const Profile: React.FC<ProfileProps> = ({ formTitle }) => {
    const { t } = useTranslation();
    const [isUpdating, setIsUpdating] = useState(false);
    const profileData = useSelector((state: RootState) => state.profile);
    const dispatch = useDispatch();
    let formTitleToPut = formTitle ?? t("Edit Profile");

    const [form, setForm] = useState({
        name: profileData.name,
        email: profileData.email,
        phone: profileData.phone,
        invoiceToCompany: profileData.invoiceToCompany,
        companyName: profileData.companyName,
        companyEmail: profileData.companyEmail,
        companyNIP: profileData.companyNIP,
        companyCountry: profileData.companyCountry,
        companyAddress: profileData.companyAddress,
        agreement: profileData.agreement,
        marketingEmails: profileData.marketingEmails
    });

    const [errors, setErrors] = useState({
        name: "",
        agreement: "",
        companyName: "",
        companyNIP: ""
    });

    const handleChange = (event: any) => {
        const { name, value, checked, type } = event.target;
        setForm((prevForm) => ({ ...prevForm, [name]: type === 'checkbox' ? checked : value }));
        // Clear error message when user corrects input
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    };

    const setNewPhone = (phoneNumber?: string | null) => {
        const theNumber: string = phoneNumber ? phoneNumber : "";
        setForm((prevForm) => ({ ...prevForm, phone: theNumber }));
    };

    const validateForm = () => {
        let isValid = true;
        let newErrors: any = {};

        if (!form.name) {
            newErrors.name = "Name is required";
            isValid = false;
        }

        if (!form.agreement) {
            newErrors.agreement = "Agreement to terms and conditions is required";
            isValid = false;
        }

        if (form.invoiceToCompany) {
            if (!form.companyName) {
                newErrors.companyName = "Company Name is required";
                isValid = false;
            }
            if (!form.companyNIP) {
                newErrors.companyNIP = "Company NIP (VAT) is required";
                isValid = false;
            }
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!validateForm()) return;

        setIsUpdating(true);
        setTimeout(async () => {
            await api().patch('users/profile', {
                name: form.name,
                phone: form.phone ?? "",
                companyName: form.companyName ?? "",
                companyNIP: form.companyNIP ?? "",
                companyAddress: form.companyAddress ?? "",
                companyCountry: form.companyCountry ?? "PL",
                companyEmail: form.companyEmail ?? "",
                agreement: !!form.agreement,
                marketingEmails: !!form.marketingEmails,
                invoiceToCompany: !!form.invoiceToCompany
            });
            setIsUpdating(false);
            dispatch(setProfileData({
                _id: profileData._id,
                externalId: profileData.externalId,
                name: form.name,
                email: profileData.email,
                phone: form.phone ?? "",
                role: profileData.role,
                companyNIP: form.companyNIP ?? "",
                companyCountry: '',
                companyAddress: '',
                companyName: form.companyName ?? "",
                companyEmail: form.companyEmail ?? "",
                agreement: form.agreement,
                invoiceToCompany: form.invoiceToCompany,
                marketingEmails: form.marketingEmails,
            }));
            toast.success(t("Profile has been updated successfully"));
        }, 1);
    };

    return (
        <Box sx={{ maxWidth: 600, margin: 'auto', padding: 2, paddingTop: "50px" }}>
            <Typography variant="h4" component="h1">
                {isUpdating ? t("Profile is updating") : formTitleToPut}
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    name="name"
                    label="Name"
                    fullWidth
                    margin="normal"
                    value={form.name}
                    onChange={handleChange}
                    required
                    error={!!errors.name}
                    helperText={errors.name}
                />
                <TextField
                    name="email"
                    label="Email"
                    fullWidth
                    margin="normal"
                    value={form.email}
                    disabled
                />
                <PhoneInput
                    className={"profile-phone"}
                    placeholder="Phone Number (optional)"
                    value={form.phone}
                    labels={pl}
                    defaultCountry={"PL"}
                    onChange={setNewPhone}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            name="invoiceToCompany"
                            checked={form.invoiceToCompany}
                            onChange={handleChange}
                        />
                    }
                    label="Invoice to company"
                />
                {form.invoiceToCompany && (
                    <>
                        <TextField
                            name="companyName"
                            label="Company Name"
                            fullWidth
                            margin="normal"
                            value={form.companyName}
                            onChange={handleChange}
                            required={form.invoiceToCompany}
                            error={!!errors.companyName}
                            helperText={errors.companyName}
                        />
                        <TextField
                            name="companyNIP"
                            label="Company NIP (VAT)"
                            fullWidth
                            margin="normal"
                            value={form.companyNIP}
                            onChange={handleChange}
                            required={form.invoiceToCompany}
                            error={!!errors.companyNIP}
                            helperText={errors.companyNIP}
                        />
                        <TextField
                            name="companyEmail"
                            label="Company email (optional)"
                            fullWidth
                            margin="normal"
                            type="email"
                            value={form.companyEmail}
                            onChange={handleChange}
                            required={false}
                        />
                        <FormControl sx={{ minWidth: 180, marginTop: "15px", marginBottom: "10px" }}>
                            <InputLabel shrink>Company country</InputLabel>
                            <Select
                                name="companyCountry"
                                value={form.companyCountry}
                                onChange={handleChange}
                                label="Company country"
                            >
                                {Object.entries(CountriesList).map(([code, name]) => (
                                    <MenuItem key={code} value={code}>{name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            name="companyAddress"
                            label="Company address (optional)"
                            fullWidth
                            margin="normal"
                            type="text"
                            value={form.companyAddress}
                            onChange={handleChange}
                            required={false}
                        />
                    </>
                )}
                <br />
                <FormControlLabel
                    control={
                        <Checkbox
                            name="agreement"
                            checked={form.agreement}
                            onChange={handleChange}
                        />
                    }
                    label="I agree to terms and conditions and privacy policy"
                />
                {errors.agreement && <Typography color="error">{errors.agreement}</Typography>}
                <FormControlLabel
                    control={
                        <Checkbox
                            name="marketingEmails"
                            checked={form.marketingEmails}
                            onChange={handleChange}
                        />
                    }
                    label="Send me feature updates and marketing emails"
                />
                <Box sx={{ marginTop: 2 }}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{ backgroundColor: '#54b788', '&:hover': { backgroundColor: '#449a6b' } }}
                    >
                        Save
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

export default Profile;
