
export interface TherapyConfiguration {
    speed: number;
    sets: number;
    cycles: number;
    state: TherapyState;
    directionChange: DirectionChange;
    lightSize: number;
    lightShape: LightShape;
    lightPath: LightPathType;
    colorPreset: ColorPresetType;
}

export enum TherapyState {
    stopped = 'stopped',
    inProgress = 'inProgress',
    paused = 'paused',
}
export enum DirectionChange {
    smooth = 'smooth',
    linear = 'linear',
    saccade = 'saccade',
}

export enum LightPathType {
    top= 'top',
    left= 'left',
    right= 'right',
    figure8 = 'figure8',
    random= 'random'
}
export enum LightShape {
    round= 'round',
    square= 'square',
}
export enum ColorPresetType {
    light= 'light',
    dark= 'dark',
}
