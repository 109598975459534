import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import "./Home.css";
import {Link} from "react-router-dom";
import {makeLink} from "../../common/languages-helper";

interface HomeProps {
}
const Home: React.FC<HomeProps> = ({}) => {
    const profileData = useSelector((state: RootState) => state.profile);
    return <>
        <div className={"patient-info"}>
            <h1>Welcome to EMDRLINK.PL</h1>
            <br />
            Name: {profileData.name}<br />
            Email: {profileData.email}<br />
            <br />
            <Link to={makeLink("/therapist")}>Go to therapist portal</Link>
        </div>
    </>
}
export default Home;
