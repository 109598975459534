import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import "./LoginNavigation.css";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

interface LoginNavigationProps {
    isAuthenticated:boolean,
    loginFunction:any,
    logout:any
}
const LoginNavigation: React.FC<LoginNavigationProps> = ({loginFunction, logout, isAuthenticated}) => {
    const profileData = useSelector((state: RootState) => state.profile);
    const {t} = useTranslation();
    if(!profileData) {
        return <div></div>
    }
    return <div className={"login-widget"}>
        {isAuthenticated ?
            <>
                <Link to={"/profile"}>Profile</Link> / <a href="#" onClick={() => logout()}>{t("Logout")}</a>
            </>
            :
            <>
                <a href={"#"} onClick={(event:any)=>{loginFunction(null,event)}}>Sign In</a> /  <a href={"#"} onClick={(event:any) => loginFunction({authorizationParams: { screen_hint: "signup" }},event)}>Sign Up</a>
            </>
        }
    </div>
}
export default LoginNavigation;
