import {useEffect, useRef} from "react";
import {DirectionChange, TherapyConfiguration, TherapyState} from "../therapy-configuration";
import "./SpeedControl.css";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store";
import {increaseCycles, increaseSets, setCycles, setSets, setSpeed, setState} from "../therapyConfigurationSlice";

const slowSpeed = 5;
const normalSpeed = 10;
const fastSpeed = 19;

interface SpeedControlProps {

}
const SpeedControl: React.FC<SpeedControlProps> = ({}) => {

    const therapyConfiguration = useSelector((state: RootState) => state.therapyConfiguration);
    const dispatch = useDispatch();

    const setSlowSpeed = () => {

        dispatch(setSpeed(slowSpeed));
    }
    const setNormalSpeed = () => {
        dispatch(setSpeed(normalSpeed));
    }
    const setFastSpeed = () => {
        dispatch(setSpeed(fastSpeed));
    }
    const isSlow = () => {
        return therapyConfiguration.speed === slowSpeed;
    }
    const isNormal = () => {
        return therapyConfiguration.speed === normalSpeed;
    }
    const isFast = () => {
        return therapyConfiguration.speed === fastSpeed;
    }

    return <div>

        <span className={"my-speed-label"}>My Speed:</span> {' '}
        {isSlow() ? <b>Slow</b> : <a href={"#"} className={"pointer"} onClick={setSlowSpeed}>Slow</a>}
        {' '}|{' '}
        {isNormal() ? <b>Normal</b> : <a href={"#"} className={"pointer"} onClick={setNormalSpeed}>Normal</a>}
        {' '}|{' '}
        {isFast() ? <b>Fast</b> : <a href={"#"} className={"pointer"} onClick={setFastSpeed}>Fast</a>}
    </div>
}

export default SpeedControl;
