import {toast} from "react-toastify";
import {makeLink} from "../../common/languages-helper";

export const copyInviteText = (invite:any, profileData:any) => {
    let text = `EMDRlink | session #${invite.inviteeName}

Hey, please join session by clicking the link: ${getLinkAddress(invite)}
                
Looking forward to talking to you!
                
Therapist: #${profileData.name}`
    navigator.clipboard.writeText(text);
    toast.success("Invitation to " + invite.inviteeName + " has been copied to clipboard.");
    return text;
}
export const getLinkAddress = (invite:any) => {
    return window.location.protocol + "//" + window.location.host + makeLink("/jsession-")+invite._id;
}