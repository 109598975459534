import axios, {AxiosInstance} from 'axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

let theApi:AxiosInstance;
let errorForReloadShowed=false;

export const reloadApiConfig = (token:string) => {
    const config = {
        baseURL: process.env.REACT_APP_API_URL,
        //15 minutes timeout
        timeout: 15*60*1000,
        headers: {
            ...(token && { Authorization: `Bearer ${token}` }),
        },
    }
    theApi = axios.create(config);
    theApi.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            // const { t } = useTranslation();

            /*if (error.response?.status === 401) {
                toast.error('apiError401');
            }
            if (error.response?.status === 500) {
                toast.error('apiError500');
            }*/
            if(error.response?.status === 429 || error.response?.status === 404) {
                //it is a too many requests from our rate limitng. We handle it separately ourselves
                //also 404 we should find ourselves
            } else if (error.response?.status >= 400 && error.response?.status < 500) {
                // error.response.data.message.forEach(message => {
                //     toast.error(message, {
                //         autoClose: 5000,
                //     });
                // });
                toast.error('Some error happened, please consider reloading the page');
            } else {
                if(!errorForReloadShowed) {
                    //showing error only once, as there could be multiple
                    errorForReloadShowed = true;
                    toast.error("Oops! Error occurred, the page will reload shortly.",
                        {
                            onClose: () => {
                                window.location.reload();
                            }
                        }
                    );
                }

            }
            return Promise.reject(error);
        },
    );
    return theApi;
}


theApi = reloadApiConfig(localStorage.getItem('tok')??"");

const api = () => {
    return theApi;
}


export default api;
