import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import * as React from 'react';
import {makeLink} from "../../common/languages-helper";

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Typography,
    Box,
    IconButton
} from '@mui/material';
import Header from "../../navigation/Header";
import {useEffect, useState} from "react";
import api from "../../api/api";
import InviteDetails from "./popups/InviteDetails";
import {TherapyInvite} from "./therapy-invite";
import CreateInvite from "./popups/CreateInvite";
import {toast} from "react-toastify";

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {copyInviteText} from "./invite-copy-functions";



interface InvitesTableProps {

}
const InvitesTable: React.FC<InvitesTableProps> = ({}) => {
    const [rows, setRows] = useState<any>([]);
    const [detailsPopupOpen, setDetailsPopupOpen] = useState<boolean>(false);
    const [createPopupOpen, setCreatePopupOpen] = useState<boolean>(false);
    const [currentInviteDetails, setCurrentInviteDetails] = useState<TherapyInvite|null>(null);
    const profileData = useSelector((state: RootState) => state.profile);
    useEffect(() => {
        if(!createPopupOpen) {
            refreshInvites();
        }
    },[createPopupOpen])

    const handleOpenInviteDetails = (invite:TherapyInvite) => {
        setCurrentInviteDetails(invite);
        setDetailsPopupOpen(true);
    }
    const handleCreateInvite = () => {
        setCreatePopupOpen(true);
    }
    const refreshInvites = async () => {
        const invitesData = await api().get("invites/" + profileData._id);
        // console.log(invitesData.data);
        setRows(invitesData.data);
    }
        const handleStartSession = (sessionId:string) => {
            /* eslint-disable no-restricted-globals */
            window.open(makeLink("/session-") + sessionId, "therapist-window",`
                directories=0,titlebar=0,toolbar=0,location=0,status=0,
                menubar=0,scrollbars=no,resizable=no,
                width=400,height=` + screen.height);
        }
    const deleteInvite = async (inviteId:string) => {
        /* eslint-disable no-restricted-globals */
        if(confirm("Are you sure you want to delete invite?")) {
            await api().delete("invites/" + inviteId);
            toast.success("Invite has been deleted successfully");
            refreshInvites();
        }
    }

    return (
        <>
            <CreateInvite createPopupOpen={createPopupOpen} setCreatePopupOpen={setCreatePopupOpen} />
            <InviteDetails invite={currentInviteDetails} detailsPopupOpen={detailsPopupOpen} setDetailsPopupOpen={setDetailsPopupOpen} />

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell variant="head" sx={{ fontWeight: 'bold' }}>Invites</TableCell>
                            <TableCell variant="head" sx={{ fontWeight: 'bold' }}>Comment</TableCell>
                            <TableCell align="left" variant="head" sx={{ fontWeight: 'bold' }}>Date Created</TableCell>
                            <TableCell align="center" variant="head" sx={{ fontWeight: 'bold', width: "10%" }} ></TableCell>
                            <TableCell align="right" variant="head" sx={{ fontWeight: 'bold', width: "10%"  }}></TableCell>
                            <TableCell align="right" variant="head" sx={{ fontWeight: 'bold', width: "10%"  }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row:any, index:number) => (
                            <TableRow
                                key={index}
                                // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                sx={{
                                    '&:last-child td, &:last-child th': { border: 0 },
                                    backgroundColor: index % 2 === 0 ? '#f9f9f9' : 'white',
                                    '&:hover': {
                                        backgroundColor: '#f1f1f1',
                                    },
                                }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.inviteeName}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.comment}
                                </TableCell>
                                <TableCell align="left">{new Date(row.createdAt).toLocaleString()}</TableCell>
                                <TableCell align="center"  sx={{ width: '10%', whiteSpace: "nowrap" }}>
                                    <IconButton onClick={()=>{copyInviteText(row, profileData) }} aria-label="copy">
                                        <ContentCopyIcon />
                                    </IconButton>
                                    {' '}
                                    <Button
                                        variant="contained"
                                        onClick={()=>{handleOpenInviteDetails(row)}}
                                        // sx={{ backgroundColor: '#54b788', '&:hover': { backgroundColor: '#449a6b' } }}
                                    >
                                        Show Details
                                    </Button>
                                </TableCell>
                                <TableCell align="right"  sx={{ width: '10%', whiteSpace: "nowrap" }}>
                                    <Typography>
                                        <Button
                                            variant="contained"
                                            onClick={()=> {handleStartSession(row._id)}}
                                            sx={{ backgroundColor: '#54b788', '&:hover': { backgroundColor: '#449a6b' } }}
                                        >
                                            Start Session
                                        </Button>
                                    </Typography>
                                </TableCell>
                                <TableCell align="right"  sx={{ width: '10%', color: "#861a22", cursor: "pointer"}}>
                                    <div className={"pointer"} onClick={()=>{deleteInvite(row._id)}}>DELETE INVITE</div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', padding: 2 }}>
                <Button
                    variant="contained"
                    onClick={handleCreateInvite}
                    sx={{ backgroundColor: '#54b788', '&:hover': { backgroundColor: '#449a6b' } }}
                >
                    New Invite
                </Button>
            </Box>

        </>
    )

}
export default InvitesTable;
