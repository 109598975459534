import React, { useState } from 'react';
import {Navigate} from "react-router-dom";
import {getSwitchLanguageOnCurrentUrl, geLanguageFromUrl, getAvailableLanguagesWithNames, LanguageOption, getLanguageOptionByCode} from "../../common/languages-helper";


const languages: LanguageOption[] = getAvailableLanguagesWithNames();

const LanguageMenu: React.FC = () => {
 const [selectedLanguage, setSelectedLanguage] = useState<LanguageOption | undefined>(getLanguageOptionByCode(geLanguageFromUrl()));
 const [isOpen, setIsOpen] = useState<boolean>(false);
 const [navigateTo, setNavigateTo] = useState<string>("");


 const handleLanguageChange = (language: LanguageOption) => {

   setSelectedLanguage(language);
   if(geLanguageFromUrl()===language.code) {
        //nothing really to switch
           return;
   }
   setNavigateTo(getSwitchLanguageOnCurrentUrl(language.code));
    setTimeout(()=>{setNavigateTo("")},100)
   setIsOpen(false);
 };

 return (
   <div className="dropdown" style={{ position: 'relative', display: 'inline-block' }}>
     <button
       onClick={() => setIsOpen(!isOpen)}
       className="dropdown-button"
       style={{
         padding: '0 0 0 15px',
         border: 'none',
         background: 'none',
         cursor: 'pointer'
       }}
     >
       {selectedLanguage ? selectedLanguage.code : 'pl'} ▼
     </button>
     {isOpen && (
       <ul className="dropdown-menu" style={{
         listStyle: 'none',
         padding: '0',
         margin: '6px -34px 0px',
         border: '1px solid #ccc',
         position: 'absolute',
         zIndex: 200,
         backgroundColor: '#fff'
       }}>
         {languages.map((language) => (
           <li
             key={language.code}
             onClick={() => handleLanguageChange(language)}
             style={{
               padding: '10px',
               cursor: 'pointer',
               backgroundColor: selectedLanguage && selectedLanguage.code === language.code ? '#e0e0e0' : '#fff',
//                hover: {backgroundColor: '#d0d0d0'}
             }}
           >
             {language.name}
           </li>
         ))}
       </ul>
     )}
    {navigateTo && <Navigate to={navigateTo} />}
   </div>
 );
};

export default LanguageMenu;
