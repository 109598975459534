import {DirectionChange, TherapyState} from "../therapy-configuration";

let currentSessionTime = 0;
let speed = 1; // Speed from 1 to 10
let lightBallPosition = 50;
let isStopRequested = false;
let elementToChangePosition: any = null;
let directionChange = 'linear'; // 'linear', 'smooth', 'saccade'
let addToCyclesFunction:any;

function calculateLightBallPosition(currentSessionTime: number, speed: number, directionChange: string): number {
    const cycleDuration = 20 / speed;
    const cycleProgress = (currentSessionTime % cycleDuration) / cycleDuration;

    let position: number;

    switch (directionChange) {
        case 'smooth':
            position = calculateSmoothPosition(cycleProgress);
            break;
        case 'saccade':
            position = calculateSaccadePosition(cycleProgress);
            break;
        case 'linear':
        default:
            position = calculateLinearPosition(cycleProgress);
            break;
    }

    return position;
}

function calculateLinearPosition(cycleProgress: number): number {
    if (cycleProgress < 0.25) {
        return 50 + 200 * cycleProgress; // 50% to 100%
    } else if (cycleProgress < 0.75) {
        return 100 - 200 * (cycleProgress - 0.25); // 100% to 0%
    } else {
        return 0 + 200 * (cycleProgress - 0.75); // 0% to 50%
    }
}

function calculateSmoothPosition(cycleProgress: number): number {
    const easeInOutQuad = (t: number) => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;

    // Handle the first transition from 50% to 100% smoothly
    if (currentSessionTime < (20 / speed)) {
        if (cycleProgress < 0.5) {
            return 50 + 50 * easeInOutQuad(cycleProgress * 2); // 50% to 100%
        } else {
            return 100 - 100 * easeInOutQuad((cycleProgress - 0.5) * 2); // 100% to 0%
        }
    }

    // After the first cycle, go from 0% to 100% and back to 0% smoothly
    if (cycleProgress < 0.5) {
        return 100 * easeInOutQuad(cycleProgress * 2); // 0% to 100%
    } else {
        return 100 - 100 * easeInOutQuad((cycleProgress - 0.5) * 2); // 100% to 0%
    }
}

function calculateSaccadePosition(cycleProgress: number): number {
    if (cycleProgress < 0.5) {
        return 100; // Appear at 100%
    } else {
        return 0; // Appear at 0%
    }
}

let startTime: number | null = null;
let previousLightBallPosition:number = 0;
let currentDirection = 1;

function animateLightBall(timestamp: number) {
    if (!startTime) startTime = timestamp;
    const elapsedTime = (timestamp - startTime) / 1000; // Convert to seconds
    currentSessionTime = elapsedTime;

    // Calculate the position
    lightBallPosition = calculateLightBallPosition(currentSessionTime, speed, directionChange);

    if(directionChange === DirectionChange.saccade) {
        if(previousLightBallPosition && previousLightBallPosition === 100 && lightBallPosition === 0) {
            addToCyclesFunction();
        }
    } else {
        if (lightBallPosition > previousLightBallPosition && currentDirection < 0) {
            addToCyclesFunction();
            currentDirection = 1;
        } else if (lightBallPosition > previousLightBallPosition) {
            currentDirection = 1;
        } else if (lightBallPosition < previousLightBallPosition) {
            currentDirection = -1;
        }
     }


    previousLightBallPosition = lightBallPosition;
    // Update the light ball position
    if (elementToChangePosition && elementToChangePosition.current) {
        elementToChangePosition.current.style.left = `${lightBallPosition}%`;
    }

    // Request the next animation frame
    if (!isStopRequested) {
        requestAnimationFrame(animateLightBall);
    } else {
        lightBallPosition = 50;
        startTime = null;
        setTimeout(()=>{
            isStopRequested = false;
        },100);
    }
}

export function startTimer(element: any, desiredSpeed = 1, desiredDirectionChange = 'linear', state:TherapyState = TherapyState.inProgress, addToCycles:any) {
    elementToChangePosition = element;
    addToCyclesFunction = addToCycles;
    if(!startTime) {
        startTime = null;
    }
    speed = desiredSpeed;
    directionChange = desiredDirectionChange;
    if(state === TherapyState.inProgress) {
        requestAnimationFrame(animateLightBall);
    } else {
        stopTimer();
    }
}

export function stopTimer() {
    isStopRequested = true;
    lightBallPosition = 50;
    startTime = null;
    previousLightBallPosition = 0;
    currentDirection = 1;
    setTimeout(()=>{
        isStopRequested = false;
    },50)
}
