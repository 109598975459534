import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AuthState {
    isLoading:boolean,
    error:any,
    isAuthenticated:boolean,
    user:any,
    logout:any,
    getAccessTokenSilently:any,
    getIdTokenClaims:any,
    loginWithRedirect:any
}
const initialState = {
    isLoading:true,
    error:"",
    isAuthenticated:false,
    user:null,
    logout:null,
    getAccessTokenSilently:null,
    getIdTokenClaims:null,
    loginWithRedirect:null
}
export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setIsLoading: (state, action:PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setError: (state, action:PayloadAction<any>) => {
            state.error = action.payload;
        },
        setIsAuthenticated: (state, action:PayloadAction<boolean>) => {
            state.isAuthenticated = action.payload;
        },
        setUser: (state, action:PayloadAction<any>) => {
            state.user = action.payload;
        },
        setLogout: (state, action:PayloadAction<any>) => {
            state.logout = action.payload;
        },
        setGetAccessTokenSilently: (state, action:PayloadAction<any>) => {
            state.getAccessTokenSilently = action.payload;
        },
        setGetIdTokenClaims: (state, action:PayloadAction<any>) => {
            state.getIdTokenClaims = action.payload;
        },
        setLoginWithRedirect: (state, action:PayloadAction<any>) => {
            state.loginWithRedirect = action.payload;
        },
    }
});
export const { setIsLoading, setError, setIsAuthenticated, setUser, setLogout, setGetAccessTokenSilently, setGetIdTokenClaims, setLoginWithRedirect } = authSlice.actions;

export default authSlice.reducer;
