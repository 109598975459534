import { configureStore } from '@reduxjs/toolkit';

// Example slice reducer
import profileReducer from '../components/profile/profileSlice';
import therapyConfigurationReducer from "../components/pages/therapy-session/therapyConfigurationSlice";
import authReducer from "../components/navigation/login/authSlice";

const store = configureStore({
    reducer: {
        profile: profileReducer,
        therapyConfiguration: therapyConfigurationReducer,
        auth: authReducer
    },
});

// Export RootState and AppDispatch types for use in your app
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;