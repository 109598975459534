import React, {useEffect, useState, useRef} from 'react';
import './App.css';
import {useTranslation} from "react-i18next";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {LanguageContext} from './LanguageContext';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {PageMetaData} from "./components/common/paget-meta-data";
import RoutesHandler from "./RoutesHandler";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "./store";
import {setProfileData} from "./components/profile/profileSlice";
import {useAuth0} from '@auth0/auth0-react';
import {
    setError,
    setGetAccessTokenSilently, setGetIdTokenClaims,
    setIsAuthenticated,
    setIsLoading, setLoginWithRedirect,
    setLogout,
    setUser
} from "./components/navigation/login/authSlice";
import Header from "./components/navigation/Header";
import {reloadApiConfig} from "./components/api/api";
import {getProfile} from "./components/profile/get-profile";
import {ColorPresetType} from "./components/pages/therapy-session/therapy-configuration";

function App() {
    const {t} = useTranslation();
    const [lang, setLang] = useState('pl'); // default language
    const [pageTitle, setPageTitle] = useState("emdrlink.pl");
    // const [profileData, setProfileData] = useState()
    let {isLoading, error, isAuthenticated, user, logout, getAccessTokenSilently, getIdTokenClaims, loginWithRedirect} =
        useAuth0();

    const profileData = useSelector((state: RootState) => state.profile);
    const therapyConfiguration = useSelector((state: RootState) => state.therapyConfiguration);
    const dispatch = useDispatch();

    const loginFunction = (config:any = null, event:any = null) => {
        if(event) {
            event.preventDefault(); // This stops the browser from navigating to the link.
            event.stopPropagation();
        }
        if(config) {
            return loginWithRedirect(config);
        } else {
            return loginWithRedirect();
        }
    }
    const logoutFunction = (event:any) => {
        if(event) {
            event.preventDefault(); // This stops the browser from navigating to the link.
            event.stopPropagation();
        }
        localStorage.removeItem('tok');
        localStorage.removeItem('uid');
        return logout();
    }

/*
    dispatch(setIsLoading(isLoading));
    dispatch(setError(error));
    dispatch(setIsAuthenticated(isAuthenticated));
    dispatch(setUser(user));
    dispatch(setLogout(logout));
    dispatch(setGetAccessTokenSilently(getAccessTokenSilently));
    dispatch(setGetIdTokenClaims(getIdTokenClaims));
    dispatch(setLoginWithRedirect(loginWithRedirect));

    const authData = useSelector((state: RootState) => state.auth);
*/

    let addClassName = "";
    if (document.location.href.includes("csession")) {
        addClassName = "csession";
    }

    /*  const setPageMetaData = (metadata:PageMetaData) => {
        if(metadata.title) {
          setPageTitle(metadata.title + " - emdrlink.pl");
        } else {
          setPageTitle("emdrlink.pl");
        }
      }*/
/*    useEffect(() => {
        // i18n.changeLanguage('pl');
        setTimeout(() => {
            dispatch(setProfileData(
                {
                    _id: "123123",
                    externalId: "WP123",
                    name: "Alex",
                    email: "alex@emdrlink.pl",
                    role: "therapist"
                },
            ))
        }, 500);
    }, []);*/
    useEffect(() => {
        if(isAuthenticated) {
            // console.log('AUTHENTICATED!!');
            if(localStorage.getItem("redirect_after_login")) {
                const redirectPath = localStorage.getItem("redirect_after_login");
                localStorage.removeItem("redirect_after_login");
                if(redirectPath) {
                    window.location.href = redirectPath;
                }
                //will be redirect
                // return <div className={"background-image main-container"}></div>;
            }
            getAccessTokenSilently().then(
                async function (value) { /* code if successful */
                    localStorage.setItem("tok", value);
                    reloadApiConfig(value);
                    let theProfile:any;
                    theProfile = await getProfile();
                    // if (!theProfile) {
                        dispatch(setProfileData(theProfile));
                    // }
                    //setTimeout(async ()=>{ let anotherProfile = await getProfile(); console.log(anotherProfile);},10000);
                    if (theProfile && theProfile._id) {
                        localStorage.setItem('uid', theProfile._id);
                    }
                    // setIsPageLoading(false);
                },
                function (error) { /* code if some error */
                    console.log('Is error found here? ' + error);
                }
            );
        }
    },[isAuthenticated]);
    return (
        <Router>
            <LanguageContext.Provider value={{lang, setLang}}>
                <div className={"main-body" + " " + addClassName + (therapyConfiguration.colorPreset === ColorPresetType.dark ? " dark-interaction-part" : " light-interaction-part")}>
                    <HelmetProvider>
                        <Helmet>
                            <title>{pageTitle}</title>
                        </Helmet>
                    </HelmetProvider>
                    <ToastContainer/>
                    <Header isAuthenticated={isAuthenticated} loginFunction={loginFunction} logout={logoutFunction} />
                    <Routes>
                        <Route path="/" element={<RoutesHandler isAuthenticated={isAuthenticated} loginFunction={loginFunction} logout={logoutFunction} isLoading={isLoading} /> }/>
                        <Route path="/:langId" element={<RoutesHandler isAuthenticated={isAuthenticated} loginFunction={loginFunction} logout={logoutFunction} isLoading={isLoading} />}/>
                        <Route path="/:langId/:pageUrlPart" element={<RoutesHandler isAuthenticated={isAuthenticated} loginFunction={loginFunction} logout={logoutFunction} isLoading={isLoading} />}/>
                    </Routes>
                </div>
            </LanguageContext.Provider>
        </Router>
    );
}

export default App;
