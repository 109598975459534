import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import { Auth0Provider } from '@auth0/auth0-react';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <Auth0Provider
          domain={""+process.env.REACT_APP_AUTH0_DOMAIN}
          clientId={""+process.env.REACT_APP_AUTH0_CLIENT}
          authorizationParams={{
              redirect_uri: window.location.origin,
              scope: "openid profile email",
              responseType: 'token',
              response_type: 'token',
              audience: process.env.REACT_APP_AUTH0_AUDIENCE
          }}
          cacheLocation="localstorage"
      >
          <Provider store={store}>
            <App />
          </Provider>
      </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
