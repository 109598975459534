import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

//@ts-ignore
import translationEN from './locales/en-translation.json';

//@ts-ignore
import translationPL from './locales/pl-translation.json';

const resources = {
    pl: {
        translation: translationPL,
    },
    en: {
        translation: translationEN,
    }
};

i18n.use(initReactI18next).init({
    resources,
    lng: 'pl', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
});

export default i18n;
