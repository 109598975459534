import {useSelector} from "react-redux";
import {RootState} from "../../../../store";
import Header from "../../../navigation/Header";
import {useEffect, useState} from "react";
import {wsapi} from "../../../api/wsapi";
import TherapyForm from "./TherapyForm";
import Interaction from "../interaction/Interaction";
import {DirectionChange, LightPathType, LightShape, TherapyState} from "../therapy-configuration";
import "./HostSession.css";

interface HostSessionProps {
    sessionId:string;
}
const HostSession: React.FC<HostSessionProps> = ({sessionId}) => {
    const [isPreviewHidden, setIsPreviewHidden] = useState<boolean>(false);
    const [isClientJoined, setIsClientJoined] = useState<boolean>(false);

    const profileData = useSelector((state: RootState) => state.profile);
    const roomId =  'session-' + sessionId;
    const showPreview = () => {
        setIsPreviewHidden(false);
    }
    const hidePreview = () => {
        setIsPreviewHidden(true);
    }
    useEffect(() => {
        wsapi().emit('joinSession', { session: roomId, role: "therapist" });

/*        wsapi().on('message', (message) => {
            console.log('Received message from server:', message);
        });*/
        wsapi().on('clientJoined', (message) => {
            setIsClientJoined(true);
        });
        wsapi().on('clientDisconnected', (message) => {
           setIsClientJoined(false);
        });

        wsapi().emit('sendMessageToSession', {session: roomId, message: "Hello world"});
        wsapi().emit('sendEmdrConfiguration', {session: roomId, configuration: "Hello world"});
    }, [])
    return <div>
        {/*HostSession id: {sessionId}*/}

        {isClientJoined ?
        <div className={"client-connected"}>Client has connected</div>
            :
        <div className={"client-not-connected"}>Waiting for client to connect</div>
        }
        <div style={{margin:"10px 20px"}}>
            <TherapyForm isClientJoined={isClientJoined} setIsClientJoined={setIsClientJoined}
                previewControl = {
                <>
                    <div style={{display: isPreviewHidden? "none" : "block"}}>
                        <Interaction title={"Preview"} additionalClassName = {"preview"} isOppositePersonJoined={isClientJoined} />
                    </div>
                    <div className={"preview-show-hide-controls"} >
                        {isPreviewHidden ?
                            <div className={"pointer"} onClick={showPreview}>show preview</div>
                            :
                            <div className={"pointer"}  onClick={hidePreview}>hide preview</div>
                        }
                    </div>
                </>
            }/>

        </div>
    </div>
}
export default HostSession;
