import React from 'react';
import {Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField} from '@mui/material';
import {ColorPresetType, DirectionChange, LightPathType, LightShape, TherapyState} from '../therapy-configuration';
import "./TherapyForm.css";
import {RootState} from "../../../../store";
import {useDispatch, useSelector} from "react-redux";
import {
    initialState,
    setColorPreset,
    setCycles,
    setDirectionChange,
    setLightPath,
    setLightShape,
    setLightSize,
    setSets,
    setSpeed,
    setState,
    setTherapyConfiguration
} from "../therapyConfigurationSlice";
import {stopTimer} from "../interaction/calculate-light-position";
import SpeedControl from "../speed-control/SpeedControl";

interface TherapyFormProps {
    isClientJoined:boolean;
    setIsClientJoined:any;
    previewControl:any,
}

const TherapyForm: React.FC<TherapyFormProps> = ({isClientJoined, setIsClientJoined, previewControl}) => {
/*    const [therapyConfiguration, setTherapyConfiguration] = useState<TherapyConfiguration>({
        speed: 1,
        sets: 1,
        cycles: 1,
        state: TherapyState.stopped,
        directionChange: DirectionChange.smooth,
        lightSize: 1,
        lightShape: LightShape.round,
        lightPath: LightPathType.top,
        showPreview: true
    });*/

    const therapyConfiguration = useSelector((state: RootState) => state.therapyConfiguration);
    const dispatch = useDispatch();

    const setSpeedChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        dispatch(setSpeed(parseInt(value)));
    }


    const setLightSizeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        let theValue = parseInt(value);
        if(parseInt(value) > 5) {
            theValue = 5;
        }
        if(parseInt(value)<=0) {
            theValue = 1;
        }
        dispatch(setLightSize(theValue));
    }

/*    const setSetsChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        dispatch(setSets(parseInt(value)));
    }

    const setCyclesChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        dispatch(setCycles(parseInt(value)));
    }*/
    const setLightShapeChange = (event: SelectChangeEvent) => {
        const { name, value } = event.target;
        //@ts-ignore
        dispatch(setLightShape(value));
    }
    const setDirectionChangeChange = (event: SelectChangeEvent) => {
        const { name, value } = event.target;
        //@ts-ignore
        dispatch(setDirectionChange(value));
    }
    const setStateChange = (event: SelectChangeEvent) => {
        const { name, value } = event.target;
        //@ts-ignore
        dispatch(setState(value));
    }
    const setLightPathChange = (event: SelectChangeEvent) => {
        const { name, value } = event.target;
        //@ts-ignore
        dispatch(setLightPath(value));
    }

    const setPresetColourChange = (event: SelectChangeEvent) => {
        const { name, value } = event.target;
        //@ts-ignore
        dispatch(setColorPreset(value));
    }
    const restartSets = () => {
        dispatch(setSets(0));
        dispatch(setCycles(0));
        // therapyConfiguration.sets
    }
    /*const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setTherapyConfiguration(prevConfig => ({
            ...prevConfig,
            [name]: Number(value),
        }));
        dispatch()
    };

    const handleSelectChange = (event: SelectChangeEvent) => {
        const { name, value } = event.target;
        setTherapyConfiguration(prevConfig => ({
            ...prevConfig,
            [name as string]: value,
        }));
    };*/

    const handleStartMovement = () => {
        // Implement the logic to start the therapy session
        // console.log('Therapy session started with configuration:', therapyConfiguration);
        dispatch(setState(TherapyState.inProgress));
    };
    const handleStopMovement = () => {
        // Implement the logic to start the therapy session
        // console.log('Therapy session started with configuration:', therapyConfiguration);
        dispatch(setState(TherapyState.stopped));
        stopTimer();
    };
    const resetToDefault = () => {
        let stateToReset:any = {};
        for(let k in initialState) {
            stateToReset[k] = initialState[k];
        }
        //not reseting sets and cycles
        stateToReset.sets = therapyConfiguration.sets;
        stateToReset.cycles = therapyConfiguration.cycles;
        dispatch(setTherapyConfiguration(stateToReset));
    }
    return (
        <div className={"therapy-form"}>
            <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                <Box sx={{ display: 'flex', gap: 1 }} className={"top-row-3-elements"}>
                    <FormControl>
                        <InputLabel shrink>Direction</InputLabel>
                        <Select
                            name="directionChange"
                            value={therapyConfiguration.directionChange}
                            onChange={setDirectionChangeChange}
                            label="Direction Change"
                        >
                            {Object.values(DirectionChange).map(direction => (
                                <MenuItem key={direction} value={direction}>{direction}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ minWidth: 80 }}>
                        <InputLabel shrink>Shape</InputLabel>
                        <Select
                            name="lightShape"
                            value={therapyConfiguration.lightShape}
                            onChange={setLightShapeChange}
                            label="Light Shape"
                        >
                            {Object.values(LightShape).map(shape => (
                                <MenuItem key={shape} value={shape}>{shape}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ minWidth: 80 }}>
                        <InputLabel shrink>Colour</InputLabel>
                        <Select
                            name="colorPreset"
                            value={therapyConfiguration.colorPreset}
                            onChange={setPresetColourChange}
                            label="Colour Presets"
                        >
                            {Object.values(ColorPresetType).map(preset => (
                                <MenuItem key={preset} value={preset}>{preset}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <TextField
                        label="Speed"
                        type="number"
                        name="speed"
                        inputProps={{ min: 1, max: 20 }}
                        value={therapyConfiguration.speed}
                        onChange={setSpeedChange}
                    />
                    <TextField
                        label="Light Size"
                        type="number"
                        name="lightSize"
                        inputProps={{ min: 1, max: 5 }}
                        value={therapyConfiguration.lightSize}
                        onChange={setLightSizeChange}
                    />
                </Box>
                <SpeedControl />
               {/* <Box sx={{ display: 'flex', gap: 2 }}>
                    <FormControl sx={{ minWidth: 120 }}>
                        <InputLabel shrink>State</InputLabel>
                        <Select
                            name="state"
                            value={therapyConfiguration.state}
                            onChange={setStateChange}
                            label="State"
                        >
                            {Object.values(TherapyState).map(state => (
                                <MenuItem key={state} value={state}>{state}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ minWidth: 180 }}>
                        <InputLabel shrink>Light Path</InputLabel>
                        <Select
                            name="lightPath"
                            value={therapyConfiguration.lightPath}
                            onChange={setLightPathChange}
                            label="Light Path"
                        >
                            {Object.values(LightPathType).map(path => (
                                <MenuItem key={path} value={path}>{path}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>*/}
                {previewControl}
                <Box sx={{ display: 'flex', gap: 2 }}>
                    {therapyConfiguration.state === TherapyState.inProgress ?
                        <Button variant="contained" color="primary" onClick={handleStopMovement}
                                sx={{ width: "70%" }}
                        >
                            Stop
                        </Button>
                    :
                        <Button variant="contained" color="primary"
                                sx={{ backgroundColor: '#54b788', '&:hover': { backgroundColor: '#449a6b' }, width: "70%" }}
                                onClick={handleStartMovement}>
                            {isClientJoined ? "Start Movement" : "Test Movement"}
                        </Button>
                    }
                    <Button variant="contained" color="secondary"
                            sx={{ backgroundColor: 'var(--red)', '&:hover': { backgroundColor: 'orange' }, width: "30%", fontSize: '8px'  }}
                            onClick={resetToDefault}>
                        Reset to default
                    </Button>
                </Box>
            </Box>

            <br />
            <div style={{visibility: (isClientJoined?"visible" : "hidden")}}>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <TextField
                        label="Sets"
                        type="number"
                        name="sets"
                        disabled={true}
                        inputProps={{ min: 1, max: 100 }}
                        value={therapyConfiguration.sets}
                    />
                    <TextField
                        label="Cycles"
                        type="number"
                        name="cycles"
                        disabled={true}
                        inputProps={{ min: 1, max: 100 }}
                        value={therapyConfiguration.cycles < 0 ? 0 : therapyConfiguration.cycles}
                    />
                </Box>
                <div className={"reset-sets-counter"} onClick={restartSets}>restart sets</div>
            </div>
        </div>
    );
};

export default TherapyForm;
