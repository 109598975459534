import * as React from "react";
import {TherapyInvite} from "../therapy-invite";
// import {Dialog, DialogTitle, DialogContent, DialogActions, Button} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store";

import {Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField} from '@mui/material';
import {useState} from "react";
import api from "../../../api/api";
import {Toast} from "react-toastify/dist/components";
import {toast} from "react-toastify";


interface InviteDetailsProps {
    createPopupOpen: any;
    setCreatePopupOpen: any;
}

const CreateInvite: React.FC<InviteDetailsProps> = ({createPopupOpen, setCreatePopupOpen}) => {
    const {t} = useTranslation();
    const profileData = useSelector((state: RootState) => state.profile);
    const [inviteeName, setInviteeName] = useState('');
    const [comment, setComment] = useState('');
    const handleClose = () => {
        setCreatePopupOpen(false);
    }
    const createInvite = async () => {
        if(!inviteeName) {
            toast.error("Please enter invitee name");
            return;
        }
        let postData:any = {inviteeName: inviteeName, userId: profileData._id};
        if(comment) {
            postData.comment = comment;
        }
        await api().post('invites', postData);
        toast.success("Invite has been created successfully");
        setInviteeName("");
        setComment("");
        handleClose();
    }
    return (
        <Dialog open={createPopupOpen} onClose={handleClose}>
            <DialogTitle>Please add information to create new invite</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="invitee-name"
                    label="Invitee Name"
                    type="text"
                    fullWidth
                    value={inviteeName}
                    onChange={(e) => setInviteeName(e.target.value)}
                />

                <TextField
                    margin="dense"
                    id="comment"
                    label="Comment"
                    type="text"
                    fullWidth
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                />
            </DialogContent>

            <DialogActions>
                <Button onClick={createInvite} color="primary">
                    {t("Create")}
                </Button>
                <Button onClick={handleClose} color="secondary">
                    {t("Cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    );

}

export default CreateInvite;