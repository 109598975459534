export function getAvailableLanguages():string[] {
    return [
        'pl',
        'en',
        'uk'
    ];
}
export interface LanguageOption {
 code: string;
 name: string;
}

export function getAvailableLanguagesWithNames():LanguageOption[] {
    return [
        {code: 'pl', name: 'Polish'},
        {code: 'en', name: 'English'},
        {code: 'uk', name: 'Ukrainian'},
    ];
}
export function getFullLanguageCodes(shortCode:string):string {
    const fullCodes = {
        'pl':'pl-PL',
        'en':'en-US',
        'uk':'uk-UA',
    };
    //@ts-ignore
    return fullCodes[shortCode] ?? 'pl-PL';
}
export function getLanguageNameByCode(code:string) {
    const languagesWithCodes = getAvailableLanguagesWithNames();
    for(let x = 0; x < languagesWithCodes.length; x++ ) {
        if(languagesWithCodes[x].code === code) {
            return languagesWithCodes[x].name;
        }
    }
}
export function getLanguageOptionByCode(code:string) {
    const languagesWithCodes = getAvailableLanguagesWithNames();
    for(let x = 0; x < languagesWithCodes.length; x++ ) {
        if(languagesWithCodes[x].code === code) {
            return languagesWithCodes[x];
        }
    }
}
export function makeLink(url:string):string {
    const availableLanguages = getAvailableLanguages();
    let currentUrl = document.location.pathname;
    let urlParts = currentUrl.split("/");
    let lang = "pl";
    if(urlParts[1] && availableLanguages.includes(urlParts[1])) {
        lang = urlParts[1];
    }
    if(lang === 'pl') {
        return url;
    } else {
        if(url === "/") {
            return "/" + lang
        } else {
            return "/" + lang + url;
        }
    }
}
export function getSwitchLanguageOnCurrentUrl(newLanguageCode:string):string {
    const availableLanguages = getAvailableLanguages();
    let currentUrl = document.location.pathname;
    let urlParts = currentUrl.split("/");
    let path = "";
    if(urlParts[1] && availableLanguages.includes(urlParts[1])) {
        if(urlParts[2]) {
            path = urlParts[2];
        }
        if(urlParts[3]) {
            path += "/" + urlParts[3];
        }
    } else if(urlParts[1]) {
        path = urlParts[1];
        if(urlParts[2]) {
            path += "/" + urlParts[2];
        }
    }
    if(!path) {
        //for Polish we also redirect to /en on a homepage as it will set it in local storage as well
        return "/" + newLanguageCode;
    } else if(newLanguageCode === "pl") {
        return "/" + path;
    } else {
        return "/" + newLanguageCode + "/" + path;
    }
}
export function geLanguageFromUrl():string {
    const availableLanguages = getAvailableLanguages();
    let currentUrl = document.location.pathname;
    let urlParts = currentUrl.split("/");
    let lang = "pl";
    if(urlParts[1] && availableLanguages.includes(urlParts[1])) {
        lang = urlParts[1];
    }
    return lang;
}
