import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    ColorPresetType,
    DirectionChange,
    LightPathType,
    LightShape,
    TherapyConfiguration,
    TherapyState
} from "./therapy-configuration";
import {wsapi} from "../../api/wsapi";


export const initialState:any = {
    speed: 10,
    sets: 0,
    cycles: -1,
    state: TherapyState.stopped,
    directionChange: DirectionChange.linear,
    lightSize: 3,
    lightShape: LightShape.round,
    lightPath: LightPathType.left,
    colorPreset: ColorPresetType.light
}
const sendUpdateToClients = (state:any) => {
    if(document.location.href.includes("/session-")) {
        const sessionId = document.location.href.split('-').pop();
        wsapi().emit("changeTherapyConfiguration", {
            session: "session-" + sessionId, therapyConfiguration: {
                speed: state.speed,
                sets: state.sets,
                cycles: state.cycles,
                state: state.state,
                directionChange: state.directionChange,
                lightSize: state.lightSize,
                lightShape: state.lightShape,
                lightPath: state.lightPath,
                colorPreset: state.colorPreset,
            }
        })
    }
}
export const therapyConfigurationSlice = createSlice({
    name: 'therapyConfiguration',
    initialState,
    reducers: {
        setTherapyConfiguration: (state, action:PayloadAction<TherapyConfiguration>) => {
            state.speed = action.payload.speed;
            state.sets = action.payload.sets;
            state.cycles = action.payload.cycles;
            state.state = action.payload.state;
            state.directionChange = action.payload.directionChange;
            state.lightSize = action.payload.lightSize;
            state.lightShape = action.payload.lightShape;
            state.lightPath = action.payload.lightPath;
            state.colorPreset = action.payload.colorPreset;

            sendUpdateToClients(state);
            // state = action.payload;;
        },
        setSpeed: (state, action:PayloadAction<number>) => {
            state.speed = action.payload;
            sendUpdateToClients(state);
        },
        setSets: (state, action:PayloadAction<number>) => {
            state.sets = action.payload;
            sendUpdateToClients(state);
        },
        setCycles: (state, action:PayloadAction<number>) => {
            state.cycles = action.payload;
            sendUpdateToClients(state);
        },
        increaseSets: state => {
            state.sets += 1;
            sendUpdateToClients(state);
        },
        increaseCycles: state => {
            state.cycles += 1;
            sendUpdateToClients(state);
        },
        setState: (state, action:PayloadAction<TherapyState>) => {
            state.state = action.payload;
            sendUpdateToClients(state);
        },
        setDirectionChange: (state, action:PayloadAction<DirectionChange>) => {
            state.directionChange = action.payload;
            sendUpdateToClients(state);
        },
        setLightSize: (state, action:PayloadAction<number>) => {
            state.lightSize = action.payload;
            sendUpdateToClients(state);
        },
        setLightShape: (state, action:PayloadAction<LightShape>) => {
            state.lightShape = action.payload;
            sendUpdateToClients(state);
        },
        setLightPath: (state, action:PayloadAction<LightPathType>) => {
            state.lightPath = action.payload;
            sendUpdateToClients(state);
        },
        setColorPreset: (state, action:PayloadAction<ColorPresetType>) => {
            state.colorPreset = action.payload;
            sendUpdateToClients(state);
        },
    }
});
export const { setTherapyConfiguration, setCycles, setSpeed, setDirectionChange, setLightShape, setLightSize, setLightPath, setSets, setState, increaseSets, increaseCycles, setColorPreset} = therapyConfigurationSlice.actions;

export default therapyConfigurationSlice.reducer;
