import {Button} from "@mui/material";
import * as React from "react";
import "./BeforeClientStartJoin.css";
import {useState} from "react";
interface VisitorSessionProps {
    sessionId: string;
}
const BeforeClientStartJoin: React.FC<VisitorSessionProps> = ({sessionId}) => {
    const [sessionOpened, setSessionOpened] = useState<boolean>(false);
    // const profileData = useSelector((state: RootState) => state.profile);
    const handleStartSession = (sessionId:string) => {
        /* eslint-disable no-restricted-globals */
        window.open("/csession-" + sessionId, "therapy-session",`
            directories=0,titlebar=0,toolbar=0,location=0,status=0,
            menubar=0,scrollbars=no,resizable=no,
            width=${screen.width},height=350`);
        setSessionOpened(true);

    }

    return <div>
        {/*Visitore id: {sessionId}*/}
        <div className={"join-session-button-container"}>
            {sessionOpened && <span>Opened session in new window{' '} <br /> <br /></span>}
            <Button
                variant="contained"
                onClick={()=> {handleStartSession(sessionId)}}
                sx={{ backgroundColor: '#54b788', '&:hover': { backgroundColor: '#449a6b' } }}
            >
                {sessionOpened  ? "Reconnect to session" : "Join Session"}
            </Button>

        </div>
    </div>
}
export default BeforeClientStartJoin;
