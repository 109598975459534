import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ProfileState {
    _id: string;
    externalId: string;
    name: string;
    email: string;
    role: string;
    phone: string;
    companyName: string;
    companyNIP: string;
    companyCountry: string;
    companyAddress: string;
    companyEmail: string;
    agreement: boolean;
    marketingEmails: boolean;
    invoiceToCompany: boolean;
}
const initialState = {
    _id: "",
    externalId: "",
    name: "",
    email: "",
    role: "",
    phone: "",
    companyName: "",
    companyNIP:"",
    companyAddress:"",
    companyCountry:"",
    companyEmail:"",
    agreement:false,
    marketingEmails:false,
    invoiceToCompany:false
}
export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setProfileData: (state, action:PayloadAction<ProfileState>) => {
            state._id = action.payload._id;
            state.externalId = action.payload.externalId;
            state.name = action.payload.name;
            state.email = action.payload.email;
            state.role = action.payload.role;
            state.phone = action.payload.phone;
            state.companyName = action.payload.companyName;
            state.companyNIP = action.payload.companyNIP;
            state.companyAddress = action.payload.companyAddress;
            state.companyCountry = action.payload.companyCountry;
            state.companyEmail = action.payload.companyEmail;
            state.marketingEmails = action.payload.marketingEmails;
            state.agreement = action.payload.agreement;
            state.invoiceToCompany = action.payload.invoiceToCompany;
            // state = action.payload;;
        }
    }
});
export const { setProfileData } = profileSlice.actions;

export default profileSlice.reducer;
