import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, ListItemButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import LanguageMenu from "../LanguageMenu/LanguageMenu";
import {makeLink} from "../../common/languages-helper";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";


interface MobileMenuProps {
    firstRowMenu:any,
    secondRowMenu:any
}

const MobileMenu: React.FC<MobileMenuProps> = ({firstRowMenu, secondRowMenu}) => {
  const [isOpen, setIsOpen] = useState(false);
    const {t} = useTranslation();

  const toggleDrawer = (state: boolean) => () => {
    setIsOpen(state);
  };

  return (
    <>
      <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
        <MenuIcon />
      </IconButton>

      <Drawer anchor="left" open={isOpen} onClose={toggleDrawer(false)}

      >
        <List>
                <ListItem button component="a" href={makeLink("/")}>
                    <ListItemButton>
                      <ListItemText primary={t("Main Page")} />
                    </ListItemButton>
                  </ListItem>
             {
                firstRowMenu.map((menuItem:any,index:number)=>{
                     return <ListItem button component="a" href={menuItem.url}>
                                <ListItemButton>
                                  <ListItemText primary={menuItem.title} />
                                </ListItemButton>
                              </ListItem>
                })
            }
            <hr />
            {
                secondRowMenu.map((menuItem:any,index:number)=>{
                     return <ListItem button component="a" href={menuItem.url}>
                                <ListItemButton>
                                  <ListItemText primary={menuItem.title} />
                                </ListItemButton>
                              </ListItem>
                })
            }

        </List>
      </Drawer>
    </>
  );
};

export default MobileMenu;
