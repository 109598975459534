import {useEffect, useRef} from "react";
import {DirectionChange, TherapyConfiguration, TherapyState} from "../therapy-configuration";
import "./Interaction.css";
import {startTimer, stopTimer} from "./calculate-light-position";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store";
import {increaseCycles, increaseSets, setCycles, setSets, setState} from "../therapyConfigurationSlice";

interface InteractionProps {
    title?: string;
    additionalClassName?: string;
    isOppositePersonJoined?:boolean;
    isClientJoined?:boolean;
}
const Interaction: React.FC<InteractionProps> = ({title="", additionalClassName="", isOppositePersonJoined= true}) => {

    const therapyConfiguration = useSelector((state: RootState) => state.therapyConfiguration);
    const dispatch = useDispatch();
    const currentStateCorrect = useRef(therapyConfiguration.state);

    useEffect(()=>{
        // if(!isOppositePersonJoined){
        dispatch(setState(TherapyState.stopped));
        // }
    },[isOppositePersonJoined]);

    const emdrLight = useRef(null);
    const addToSets = () => {
        dispatch(increaseSets());
    }
    const addToCycles = () => {
        if(currentStateCorrect.current === TherapyState.inProgress) {
            dispatch(increaseCycles());
        }
    }
    useEffect(() => {
        if(therapyConfiguration.state === TherapyState.inProgress) {
            if(isOppositePersonJoined) {
                addToSets();
            }
            dispatch(setCycles(-1));
        }
    }, [therapyConfiguration.state])

    useEffect(() => {
        currentStateCorrect.current = therapyConfiguration.state;
        if(therapyConfiguration.state === TherapyState.inProgress) {
            startTimer(emdrLight, therapyConfiguration.speed, therapyConfiguration.directionChange, therapyConfiguration.state, isOppositePersonJoined ? addToCycles : ()=>{});
        } else {
            stopTimer();
        }
    }, [therapyConfiguration.state, therapyConfiguration.speed, therapyConfiguration.directionChange])



    return <div>
        <div className={"emdr-bar-container light-size-"+therapyConfiguration.lightSize + " light-shape-"+therapyConfiguration.lightShape + (additionalClassName ? " "+ additionalClassName : "") + " colour-preset-" + therapyConfiguration.colorPreset}>
            {title && <div className={'emdr-title'}>{title}</div>}
            <div className={"emdr-bar"}>
                <div className={"emdr-bar-inner"}>
                    <div ref={emdrLight} style={{left:"calc(50%)"}} className={"emdr-light"}></div>
                </div>
            </div>
        </div>
    </div>
}

export default Interaction;
