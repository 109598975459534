import * as React from "react";
import {TherapyInvite} from "../therapy-invite";
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton} from "@mui/material";
import {useTranslation} from "react-i18next";
import {copyInviteText, getLinkAddress} from "../invite-copy-functions";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store";

interface InviteDetailsProps {
    invite: TherapyInvite | null;
    detailsPopupOpen: any;
    setDetailsPopupOpen: any;
}
const InviteDetails: React.FC<InviteDetailsProps> = ({invite, detailsPopupOpen, setDetailsPopupOpen}) => {
    const {t} = useTranslation();
    const profileData = useSelector((state: RootState) => state.profile);

    const handleClose = () => {
        setDetailsPopupOpen(false);
    }
    if(!invite) {
        return <></>;
    }
    const linkAddress = getLinkAddress(invite);
    return <Dialog open={detailsPopupOpen} onClose={handleClose}>
        <DialogTitle>
           Please see invite details, you can send it to your patient
        </DialogTitle>
        <DialogContent>
            <i>
                EMDRlink | session #{invite.inviteeName}<br /><br />
                Hey, please join session by clicking the link: <a href={linkAddress} target={"_blank"}>{linkAddress}</a> <br /><br />
                Looking forward to talking to you!<br /><br />
                Therapist: #{profileData.name}
            </i>
        </DialogContent>
        <DialogActions>
            <IconButton onClick={()=>{copyInviteText(invite, profileData)}} aria-label="copy">
                <ContentCopyIcon />
            </IconButton>
            <Button onClick={handleClose} color="primary" autoFocus>
                {t("Ok")}
            </Button>
        </DialogActions>
    </Dialog>
}

export default InviteDetails;