import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import Header from "../../navigation/Header";
import "./Portal.css";
interface PortalProps {
}
const Portal: React.FC<PortalProps> = ({}) => {
    const profileData = useSelector((state: RootState) => state.profile);
    return <>
        <div className={"patient-info"}>
            <h1>Patient portal</h1>
            <br />
            Name: {profileData.name}<br />
            Email: {profileData.email}<br />
        </div>
    </>
}
export default Portal;
